<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-searchbar v-model="searchText" debounce="500"></ion-searchbar>
      <div class="menu-wrapper" v-if="searchMenu.length">
        <ion-list>
          <ion-item v-for="item in searchMenu" :key="item.id">
            <ion-thumbnail v-if="item.thumbnail" slot="end">
              <img :src="item.thumbnail" />
            </ion-thumbnail>
            <div class="item-wrapper">
              <ion-label>{{ item.menuitemname }}</ion-label>
              <span class="item-description" v-if="item.description">{{ item.description }}</span>
              <div class="price-wrapper">
                <div class="multisize" v-if="item.sizeoption == 'multisize'">
                  <span
                    :style="{ color: getStyle.categorybackgroundcolor }"
                    v-for="(size, index) in item.size"
                    :key="index"
                    >{{ capitalize(size.name) }} : {{ filterCurrency(size.price) }}</span
                  >
                </div>
                <div class="singlesize" v-else>
                  <span :style="{ color: getStyle.categorybackgroundcolor }">{{
                    filterCurrency(item.price)
                  }}</span>
                </div>
              </div>
            </div>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {
    IonPage,
    IonContent,
    IonSearchbar,
    IonList,
    IonItem,
    IonLabel,
    IonThumbnail,
  } from '@ionic/vue'
  export default {
    name: 'Search',
    components: {
      IonPage,
      IonContent,
      IonSearchbar,
      IonList,
      IonItem,
      IonLabel,
      IonThumbnail,
    },
    data() {
      return {
        searchText: '',
      }
    },
    methods: {
      capitalize(data) {
        let capitalized = []
        data.split(' ').forEach((word) => {
          capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        })
        return capitalized.join(' ')
      },
      filterCurrency(data) {
        const prefix = 'KSh '
        let localizedCurrency = data.toLocaleString('en-KE')
        let currency = prefix.concat(localizedCurrency)
        return currency
      },
    },
    computed: {
      ...mapGetters(['getDisplayMenu', 'getStyle']),
      searchMenu: function() {
        let filteredResult = []
        if (this.searchText != '') {
          filteredResult = this.getDisplayMenu.filter((item) => {
            let menuname = item.menuitemname.toLowerCase()
            let keyword = this.searchText.toLowerCase()
            return menuname.indexOf(keyword) > -1
          })
        }
        return filteredResult
      },
    },
  }
</script>
<style lang="scss" scoped>
  .menu-wrapper {
    .subcategory-label {
      background-color: var(--ion-color-warning);
      padding: 1rem;
    }
    .item-wrapper {
      .item-name {
        word-wrap: break-word;
        white-space: normal;
      }
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0 0 0;
      .item-description {
        font-size: 0.75em;
        line-height: 1.5;
        color: var(--ion-color-medium);
      }
      .price-wrapper {
        padding: 0.5rem 0.5rem 0.5rem 0;
        .multisize {
          display: flex;
          flex-direction: column;
          span {
            font-size: 0.9em;
            line-height: 1.5;
            font-weight: 500;
            color: var(--ion-color-primary-shade);
          }
        }
        .singlesize {
          span {
            font-size: 0.9em;
            line-height: 1.5;
            font-weight: 500;
            color: var(--ion-color-primary-shade);
          }
        }
      }
    }
    ion-thumbnail {
      img {
        border-radius: 7px;
      }
    }
  }
</style>
